<template>
  <div>
    <div class="block banner bg">
      <div class="container">
        <div class="box">
          <h1>{{$t('solution1')}}</h1>
          <h2>{{$t('solutionApi')}}</h2>
          <p class="sm">{{$t('home65')}}</p>
          <el-button type="primary">{{$t('getPlan')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>{{$t('solution2')}}</h2>
        <ul class="product-list child">
          <li>
            <div class="img"><img src="@/assets/images/image10.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution3')}}</h4>
              <p>{{$t('solution4')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image21.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution5')}}</h4>
              <p>{{$t('solution6')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>
          {{$t('solution7')}}
          <div class="des">{{$t('solution8')}}</div>
        </h2>
        <ul class="list1 three">
          <li class="icon2">
            <h5>{{$t('solution9')}}</h5>
            <p>{{$t('solution10')}}</p>
            <router-link to="/productscdn">{{$t('learnmore')}} ></router-link>
          </li>
          <li class="icon1">
            <h5>{{$t('productSdk')}}</h5>
            <p>{{$t('solution11')}}</p>
            <router-link to="/productsdk">{{$t('learnmore')}} ></router-link>
          </li>
          <li class="icon4">
            <h5>{{$t('home33')}}</h5>
            <p>{{$t('solution12')}}</p>
            <router-link to="/productwaf">{{$t('learnmore')}} ></router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <PlanBox />
      </div>
    </div>
  </div>
</template>
<script>
  import PlanBox from '@/components/PlanBox.vue'

export default {
  name: "SolutionApi",
  components: {
    PlanBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('solutionApi')+'-'+ this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
</style>
